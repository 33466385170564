import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Box, Link, Collapse, Flex, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { MdOutlineKeyboardDoubleArrowDown, MdOutlineKeyboardDoubleArrowUp } from "react-icons/md";

const ProjectLayout = ({ title, description, children, href }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  // const [showOverlay, setShowOverlay] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  // const toggleOverlay = () => {
  //   setShowOverlay(!showOverlay);
  // };

  return (
    <Box position={"relative"}>
      <Box
        className="projectLayout"
        width={["100%"]}
        border="1px solid"
        borderColor="gray.700"
        borderRadius="md"
        p={4}
        boxShadow="md"
        // onMouseEnter={toggleOverlay}
        // onMouseLeave={toggleOverlay}
        transition="all 0.8s ease-in-out"
      >
        <Link
          fontSize={["md", "lg"]}
          color={"lightBlue"}
          href={href}
          isExternal
          fontWeight={"bold"}
        >
          {title}
          <ExternalLinkIcon mx="2px" />
        </Link>

        <Box fontSize={["xs", "sm", "md"]} mt={2}>
          {description}
        </Box>

        <Collapse in={isExpanded}  duration={10}>
          <Box
            mt={4}
            style={{ overflow: "hidden" }}
          >
            {children}
          </Box>
        </Collapse>

        {children && (
          <Flex
            onClick={toggleExpand}
            cursor="pointer"
            justify={"center"}
            alignItems={"center"}
            color={"lightBlue"}
            pt={5}
          >
            {!isExpanded && <MdOutlineKeyboardDoubleArrowDown size={15} />}
            {isExpanded && <MdOutlineKeyboardDoubleArrowUp />}
            <Text fontSize={["20px"]} fontFamily={"Title"}>
              {!isExpanded ? "CLICK TO EXPAND" : "CLOSE"}
            </Text>
          </Flex>
        )}
      </Box>
    </Box>
  );
};

export default ProjectLayout;
