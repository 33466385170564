import { Flex, Tag, TagLabel, Image, Text, Box } from "@chakra-ui/react";
import React from "react";

const SkillsTags = () => {
  return (
    <Box pb={"70px"}>
      <Text fontSize={["sm", "md", "lg"]} color={"gray.500"} mb={2}>
        DEVELOPMENT SKILLS:
      </Text>
      <Flex
        width={["350px", "400px"]}
        rowGap={5}
        wrap={"wrap"}
        justifyContent={"space-between"}
        marginTop={5}
      >
        <Tag size={["md", "lg"]} paddingY={1} className="skillTag">
          <Image
            marginRight={"1"}
            boxSize={["20px", "25px"]}
            src="./logo192.png"
            alt="Dan Abramov"
          />
          <TagLabel color={"gray"}>React.js</TagLabel>
        </Tag>
        <Tag size={["md", "lg"]} paddingY={1} className="skillTag">
          <Image
            marginRight={"1"}
            boxSize={["20px", "25px"]}
            src="./nextjs.png"
            alt="Dan Abramov"
          />
          <TagLabel color={"gray"}>Next.js</TagLabel>
        </Tag>
        <Tag size={["md", "lg"]} paddingY={1} className="skillTag">
          <Image
            marginRight={"1"}
            boxSize={["20px", "25px"]}
            src="./expressjs.png"
            alt="Dan Abramov"
          />
          <TagLabel color={"gray"}>Express.js</TagLabel>
        </Tag>
        <Tag size={["md", "lg"]} paddingY={1} className="skillTag">
          <Image
            marginRight={"1"}
            boxSize={["20px", "25px"]}
            src="./JS.png"
            alt="Dan Abramov"
          />
          <TagLabel color={"gray"}>JavaScript</TagLabel>
        </Tag>
        <Tag size={["md", "lg"]} paddingY={1} className="skillTag">
          <Image
            marginRight={"1"}
            boxSize={["20px", "25px"]}
            src="./mongodb.png"
            alt="Dan Abramov"
          />
          <TagLabel color={"gray"}>MongoDB</TagLabel>
        </Tag>

        <Tag size={["md", "lg"]} paddingY={1} className="skillTag">
          <Image
            marginRight={"1"}
            boxSize={["20px", "25px"]}
            src="./HTML.png"
            alt="Dan Abramov"
          />
          <TagLabel color={"gray"}>HTML5</TagLabel>
        </Tag>
        <Tag size={["md", "lg"]} paddingY={1} className="skillTag">
          <Image
            marginRight={"1"}
            boxSize={["20px", "25px"]}
            src="./Bootstrap.png"
            alt="Dan Abramov"
          />
          <TagLabel color={"gray"}>Bootstrap</TagLabel>
        </Tag>
        <Tag size={["md", "lg"]} paddingY={1} className="skillTag">
          <Image
            marginRight={"1"}
            boxSize={["20px", "25px"]}
            src="./jquery.png"
            alt="Dan Abramov"
          />
          <TagLabel color={"gray"}>jQuery</TagLabel>
        </Tag>
        <Tag size={["md", "lg"]} paddingY={1} className="skillTag">
          <Image
            marginRight={"1"}
            boxSize={["20px", "25px"]}
            src="./TypeScript.png"
            alt="Dan Abramov"
          />
          <TagLabel color={"gray"}>TypeScript</TagLabel>
        </Tag>
        <Tag size={["md", "lg"]} paddingY={1} className="skillTag">
          <Image
            marginRight={"1"}
            boxSize={["20px", "25px"]}
            src="./NodeJs.png"
            alt="Dan Abramov"
          />
          <TagLabel color={"gray"}>NodeJs</TagLabel>
        </Tag>

        <Tag size={["md", "lg"]} paddingY={1} className="skillTag">
          <Image
            marginRight={"1"}
            boxSize={["20px", "25px"]}
            src="./Tailwind.png"
            alt="Dan Abramov"
          />
          <TagLabel color={"gray"}>Tailwind CSS</TagLabel>
        </Tag>
        <Tag size={["md", "lg"]} paddingY={1} className="skillTag">
          <Image
            marginRight={"1"}
            boxSize={["20px", "25px"]}
            src="./CSS.png"
            alt="Dan Abramov"
          />
          <TagLabel color={"gray"}>CSS3</TagLabel>
        </Tag>
      </Flex>
    </Box>
  );
};

export default SkillsTags;
