import { Box, Collapse, Flex, Image, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { PiHandWavingLight } from "react-icons/pi";

const About = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Flex className="about" paddingX={"10%"}>
      <Flex my={5} height={"100px"} alignItems={"center"} gap={5}>
        <Image src="./personalphoto.jpg" rounded={"100%"} height={"100%"} />
        <Box height={"inherit"} fontSize={["14px", "16px"]}>
          <Flex>
            <Text>Hey!</Text>
            <PiHandWavingLight size={20} />
          </Flex>
          <Text> This is Negin. /nəˈɡin/</Text>
          <Text align={"left"}> Fullstack - based in Toronto</Text>
        </Box>
      </Flex>
      <Box my={5}>
        <Image
          display={{ base: "none", sm: "block" }}
          src="./coding.jpg"
          width={{ base: "100%", sm: "50%" }}
          float={"right"}
          pl={{ base: "0", sm: "5" }}
          pb={{ base: "5", sm: "0" }}
        />
        <Text
          fontSize={{ base: "20px", md: "28px", lg: "36px" }}
          fontFamily={"Title"}
          align={"left"}
          color={"lightBlue"}
          mb={2}
        >
          MY CODING JOURNEY
        </Text>
        <Image
          display={{ base: "block", sm: "none" }}
          src="./coding.jpg"
          width={{ base: "100%", sm: "50%" }}
          float={"right"}
          pl={{ base: "0", sm: "5" }}
          pb={{ base: "5", sm: "0" }}
        />
        <Text>
          I started my career in 2014 with an academic background in Industrial
          Engineering, where I gained strong analytical and problem-solving
          skills. My first role as an Assistant IT Project Manager (2014–2018)
          gave me hands-on experience managing tech projects and coordinating
          teams, which sparked my interest in software development. <br />
          <br />
          By 2019, I shifted gears into web development, drawn to the creative
          side of building web applications. Over the years, I’ve worked
          extensively with JavaScript, React, TypeScript, and Node.js, building
          dynamic, user-friendly solutions. My engineering background has helped
          me approach development with an eye for efficiency and scalability.
          <br />
          <br />
          From managing IT projects early in my career to developing seamless
          web interfaces, I’ve enjoyed blending strategic thinking with hands-on
          coding. Today, I’m driven by the challenge of turning ideas into
          engaging digital experiences.
        </Text>
        <br />
        <button
          className="custom_btn"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {!isExpanded ? "READ MORE ABOUT ME" : "READ LESS ABOUT ME"}
        </button>
      </Box>

      <Collapse in={isExpanded} animateOpacity>
        <Box style={{ overflow: "hidden" }} transition="all 1s linear">
          <Box my={5}>
            <Flex
              flexDirection={["column-reverse", "column-reverse", "row"]}
              gap={5}
            >
              <Image src="./moon.jpg" width={["100%", "100%", "50%"]} />
              <Box>
                <Text
                  fontSize={{ base: "20px", md: "28px", lg: "36px" }}
                  fontFamily={"Title"}
                  color={"lightBlue"}
                  align={"left"}
                  mb={2}
                >
                  THROUGH MY EYES
                </Text>
                Photography has been a lifelong escape for me. Through the lens,
                I explore different perspectives, play with light and shadow,
                and document moments that inspire and evoke emotion. <br /> This
                passion for photography enhances my attention to detail and my
                appreciation for aesthetics, both of which are crucial in my
                approach to design and development.
              </Box>
            </Flex>
          </Box>

          <Box my={5}>
            <Image
              display={{ base: "none", sm: "block" }}
              src="./nealphi_grayscale.jpg"
              width={{ base: "100%", sm: "50%" }}
              float={"right"}
              pl={{ base: "0", sm: "5" }}
              pb={{ base: "5", sm: "0" }}
            />
            <Text
              fontSize={{ base: "20px", md: "28px", lg: "36px" }}
              fontFamily={"Title"}
              align={"left"}
              color={"lightBlue"}
              mb={2}
            >
              MY FAVORITE CORNER
            </Text>
            <Text>
              When I'm not at the computer, you can find me in my studio, behind
              my sewing machine, indulging in my obsession with design and
              creation.
              <br /> I love experimenting with fabrics, patterns, and textures,
              bringing my ideas to life through detailed craftsmanship and
              innovative designs. This hands-on approach to creativity not only
              fuels my passion but also inspires my work in the digital realm.
            </Text>

            <Image
              display={{ base: "block", sm: "none" }}
              src="./nealphi_grayscale.jpg"
              width={{ base: "100%", sm: "50%" }}
              float={"right"}
              pl={{ base: "0", sm: "5" }}
              pt={{ base: "5", sm: "0" }}
            />
          </Box>
        </Box>
      </Collapse>

      {/* <Box>
        <Flex
          flexDirection={["column", "column", "column", "row"]}
          alignContent={""}
          gap={5}
        >
          <Box>
            <Text
              fontSize={["20px", "36px"]}
              fontFamily={"Title"}
              color={"lightBlue"}
              align={"left"}
              mb={2}
            >
              MY FAVORITE CORNER
            </Text>
            When I'm not at the computer, you can find me in my studio, behind
            my sewing machine, indulging in my obsession with design and
            creation.
            <br /> I love experimenting with fabrics, patterns, and textures,
            bringing my ideas to life through detailed craftsmanship and
            innovative designs. This hands-on approach to creativity not only
            fuels my passion but also inspires my work in the digital realm.
          </Box>
          <Image
            src="./nealphi_grayscale.jpg"
            width={["100%", "100%", "100%", "50%"]}
            height="fit-content"
            objectFit="fill"
          />
        </Flex>
      </Box> */}
    </Flex>
  );
};

export default About;
