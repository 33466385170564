import React from "react";
import { useEffect, useRef, useState } from "react";
import NavBar from "./NavBar";
import { ContactUs } from "./ContactUs";
import Skills from "./Skills";
import Intro from "./Intro";
import { Box } from "@chakra-ui/react";
import About from "./About";
import SplineAnimationBg from "./SplineAnimationBg";
import Resume from "./Resume";
import Projects from "./Projects";
import GitHubShowcase from "./GitHubShowcase";

const MainContainer = () => {
  const [isSelectedBtn, setIsSelectedBtn] = useState("Intro");
  const [isActive, setIsActive] = useState("Intro");
  const introRef = useRef(null);
  const aboutRef = useRef(null);
  const skillsRef = useRef(null);
  const projectsRef = useRef(null);
  const resumeRef = useRef(null);
  const contactRef = useRef(null);
  const contributionsRef = useRef(null);

  const onClick = (data) => {
    setIsSelectedBtn(data);
    setIsActive(data);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      // const windowHeight = window.innerHeight;
      // const documentHeight = document.documentElement.scrollHeight;

      if (scrollY < aboutRef.current.offsetTop) {
        setIsActive("Intro");
      } else if (scrollY < skillsRef.current.offsetTop) {
        setIsActive("About");
      } else if (scrollY < contributionsRef.current.offsetTop) {
        setIsActive("Skills");
      } else if (scrollY < projectsRef.current.offsetTop) {
        setIsActive("Contribution");
      }else if (scrollY < resumeRef.current.offsetTop) {
        setIsActive("Projects");
      } else if (scrollY < contactRef.current.offsetTop) {
        setIsActive("Resume");
      } else if (scrollY >= contactRef.current.offsetTop) {
        setIsActive("Contact");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    console.log(isActive);
  }, [isActive]);

  useEffect(() => {
    const scrollToRef = (ref) => {
      if (ref && ref.current) {
        window.scrollTo({
          top: ref.current.offsetTop,
          behavior: "smooth",
        });
      }
    };

    if (isSelectedBtn === "Intro") {
      scrollToRef(introRef);
    } else if (isSelectedBtn === "About") {
      scrollToRef(aboutRef);
    } else if (isSelectedBtn === "Skills") {
      scrollToRef(skillsRef);
    } else if (isSelectedBtn === "Contributions") {
      scrollToRef(contributionsRef);
    } else if (isSelectedBtn === "Projects") {
      scrollToRef(projectsRef);
    } else if (isSelectedBtn === "Resume") {
      scrollToRef(resumeRef);
    } else if (isSelectedBtn === "Contact") {
      scrollToRef(contactRef);
    }
    return setIsSelectedBtn(" ");
  }, [isSelectedBtn]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <Box
      ref={introRef}
      display={"flex"}
      flexDirection={"column"}
      position={"relative"}
    >
      <NavBar onClick={(data) => onClick(data)} isActive={isActive} />

      <SplineAnimationBg />

      <Intro />

      <div ref={aboutRef}></div>
      <About />
      <div ref={skillsRef}></div>
      <Skills />
      <div ref={contributionsRef}></div>
      <GitHubShowcase />
      <div ref={projectsRef}></div>
      <Projects />
      <div ref={resumeRef}></div>
      <Resume />
      <div ref={contactRef}></div>
      <ContactUs />
    </Box>
  );
};

export default MainContainer;
