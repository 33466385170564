import React from "react";
import Spline from "@splinetool/react-spline";

const SplineAnimationBg = () => {
  return (
    <div className="spline-bg">
      {/* <Spline scene="https://prod.spline.design/iJarCN5-PgVCr-S9/scene.splinecode" /> */}
      <Spline
        scene="https://prod.spline.design/x2q2vPqBNEYgXmCr/scene.splinecode" 
      />
    </div>
  );
};

export default SplineAnimationBg;
