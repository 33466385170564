import React from 'react'
import Spline from "@splinetool/react-spline";


const SplineAnimation = () => {
  return (
    <div className='spline'>
  <Spline
 scene="https://prod.spline.design/AG5Ig5RbBaPAMEem/scene.splinecode"       />
    </div>
  )
}

export default SplineAnimation