import React, { useState } from "react";
import { Box, Text, Icon, Flex } from "@chakra-ui/react";
import { FaGithub } from "react-icons/fa";
import LinkButton from "./LinkButton";
import { AnimatePresence, motion } from "framer-motion";
import { ExternalLinkIcon } from "@chakra-ui/icons";

const RepoCard = ({ index, repo }) => {
  const [showOverlay, setShowOverlay] = useState(false);
  return (
    <motion.div
      className="flex flex-col relative overflow-hidden max-h-[200px] min-w-[250px] border-2 border-zinc-900 justify-center items-center rounded p-5 "
      key={index}
      onHoverStart={() => setShowOverlay(true)}
      onHoverEnd={() => setShowOverlay(false)}
    >
      <AnimatePresence>
        {showOverlay && (
          <motion.div
            className="absolute flex flex-col justify-center items-center z-10 inset-0"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <Flex
              bg={"black"}
              pointerEvents={"none"}
              opacity={0.7}
              h={"100%"}
              w={"100%"}
              justifyContent="center"
              alignItems="center"
              position={"absolute"}
            />
            <Box borderRadius={"10px"} p={2} zIndex={20}>
              <LinkButton href={repo.url}>
                <Text>
                  {" "}
                  
                  SOURCE CODE
                </Text>
                <ExternalLinkIcon mx="2px" />
              </LinkButton>
            </Box>
          </motion.div>
        )}
      </AnimatePresence>
      <Flex color={"lightBlue"} my={2}>
        <Icon mx={2} boxSize={6} as={FaGithub} />
        {repo.title}
      </Flex>
      <Text fontSize="18px" mb="5px"></Text>
      <Text fontSize="14px" mb="10px" color="#555" textAlign={"justify"}>
        {repo.description}
      </Text>
      <Text fontSize="12px" color="#888" w={"full"} textAlign={"left"}>
        {repo.stack}
      </Text>
    </motion.div>
  );
};

export default RepoCard;
