import React from "react";
import Work from "./Work";
import { Box, Link, Text } from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import Education from "./Education";

const Resume = () => {
  return (
    <Box className="resume" gap={[22, 10]} px={["10%", "15%"]}>
      <Link
        fontSize={"lg"}
        color={"gray.100"}
        href="./Negin Alipanahi_resume.pdf"
        isExternal
        _hover={{ color: "darkBlue" }}
        fontWeight={"bold"}
      >
        View Full Résumé <ExternalLinkIcon mx="2px" />
      </Link>
      <Text
        fontSize={["md", "lg"]}
        as={"b"}
        color={"darkBlue"}
        borderBottom={"2px"}
      >
        PROFESSIONAL EXPERIENCE
      </Text>
      <Work
        title={"Intermediate Full-Stack Developer"}
        company={"Nullsight Inc. | Toronto"}
        date={"Sep 2024 - Nov 2024"}
      >
        <Box fontSize={["xs", "sm", "md"]} display={["none", "block"]}>
          <b>Tech Stack: </b>Next.js, TypeScript, React.js, Tailwind CSS, Git,
          Vercel
        </Box>
      </Work>
      <Work
        title={"Frontend / React Developer"}
        company={"Pelak Co. | Tehran"}
        date={"Mar 2021 - May 2024"}
      >
        <Box fontSize={["xs", "sm", "md"]} display={["none", "block"]}>
          <b>Tech Stack: </b>HTML5, CSS3, JavaScript (ES6+), TypeScript,
          React.js, Redux, Tailwind CSS, Git
        </Box>
      </Work>
      <Work
        title={"MERN Stack Developer"}
        company={"Freelance | Remote"}
        date={"Sep 2019 - Mar 2021"}
      >
        <Box fontSize={["xs", "sm", "md"]} display={["none", "block"]}>
          <b>Tech Stack: </b>MongoDB, Express.js, React.js, Node.js, RESTful
          APIs, NoSQL
        </Box>
      </Work>

      <Work
        title={"Assistant IT Project Manager"}
        company={"Golandisheh IT Co. | Tehran"}
        date={"Aug 2014 - Mar 2018"}
      >
        <Box fontSize={["xs", "sm", "md"]} display={["none", "block"]}>
          <b>Skills/Tools: </b> Project Management, Agile Methodologies, MS Project, Primavera P6
        </Box>
      </Work>

      <Text
        fontSize={["md", "lg"]}
        as={"b"}
        color={"darkBlue"}
        borderBottom={"2px"}
      >
        EDUCATION
      </Text>
      <Education
        major={
          "M.A. Design and Applied Arts"
        }
        years={"2015-2017"}
      />
      <Education
        major={
          "M.Sc. Industrial Engineering - System Management and Productivity"
        }
        years={"2013-2015"}
      />
      <Education
        major={"B.Sc. Industrial Engineering - Planning and System Analysis"}
        years={"2008-2012"}
      />
    </Box>
  );
};

export default Resume;
